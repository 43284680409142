// @ts-check

import React, { useState } from "react";
import "./App.css";
import Html5QrcodePlugin from "./Html5QrcodePlugin.jsx";

const App = (props) => {
  //   const [decodedResults, setDecodedResults] = useState([]);
  const [msg, setMsg] = useState("");

  const onNewScanResult = (decodedText, decodedResult) => {
    console.log("App [result]", decodedResult);
    const checkLtext = decodedText.indexOf("hotspot.sumbiri.com");
    if (checkLtext > 0) return (window.location.href = decodedText);
    if (checkLtext < 0) return setMsg("QR Code Not Found");
  };

  return (
    <div className="App">
      <section className="App-section">
        <div className="App-section-title">Hotspot Login QR Scan</div>
        <br />
        <br />
        <br />
        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />
        <div>
          <p>{msg}</p>
        </div>
        {/* <ResultContainerPlugin results={decodedResults} /> */}
        {/* <HowToUse /> */}
      </section>
    </div>
  );
};

export default App;
